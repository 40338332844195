import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardPageComponent } from './admin-panel/pages/dashboard/containers';
import { AuthGuard } from './admin-panel/pages/auth/guards';
import { AuthGuardUser } from './user-panel/auth/guards/auth.guard';
import { ResourceVideoPlayComponent } from './user-panel/main-panel/stream-panel/resource-video-play/resource-video-play.component';
import { EventNotFoundComponent } from './event-not-found/event-not-found.component';
import { DynamicRootComponent } from './dynamic-root/dynamic-root.component';
import { DynamicRootUserComponent } from './dynamic-root-user/dynamic-root-user.component';

const routes: Routes = [
  {
    path: 'secret',
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('./admin-panel/pages/auth/auth-routing.module').then(m => m.AuthRoutingModule)
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        data: {
          role: '1,2,3,4,5'
        },
        canActivate: [AuthGuard],
        component: DashboardPageComponent
      },
      {
        path: 'main-admin',
        data: {
          role: '1'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin-panel/pages/main-admin-manage/main-admin-manage.module').then(m => m.MainAdminModule)
      },
    ]
  },
  {
    path: `:dynamicPath`,
    component: DynamicRootUserComponent,
    children: [
      {
        path: `sign-in`,
        loadChildren: () => import('./user-panel/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
      },
      {
        path: 'stream',
        canActivate: [AuthGuardUser],
        loadChildren: () => import('./user-panel/main-panel/stream-panel/stream-panel.module').then(m => m.StreamPanelnModule)
      },
      {
        path: 'resource-video',
        component: ResourceVideoPlayComponent
      },
      {
        path: 'event-not-found',
        component: EventNotFoundComponent
      },
    ]
  },
  {
    path: `:dynamicPath`,
    component: DynamicRootComponent,
    children: [
      {
        path: 'secret',
        children: [
          {
            path: 'sign-in',
            loadChildren: () => import('./admin-panel/pages/auth/auth-routing.module').then(m => m.AuthRoutingModule)
          },
          {
            path: 'dashboard',
            pathMatch: 'full',
            data: {
              role: '1,2,3,4,5'
            },
            canActivate: [AuthGuard],
            component: DashboardPageComponent
          },
          {
            path: `polls`,
            data: {
              role: '2'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/polls/polls.module').then(m => m.PollsModule)
          },
          {
            path: `add-url`,
            data: {
              role: '2'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/Add URL/add-url.module').then(m => m.AddURLModule)
          },
          {
            path: `bulk-import`,
            data: {
              role: '3'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/bulk-import/bulk-import.module').then(m => m.BulkImportModule)
          },
          {
            path: 'register-manage',
            data: {
              role: '3',
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/registration-manager-panel/registration-manager-panel.module').then(m => m.RegistrationManagerPanelModule)
          },
          {
            path: `questions`,
            data: {
              role: '5'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/questions/questions.module').then(m => m.QuestionsModule)
          },
          {
            path: `resources`,
            data: {
              role: '2'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/resources/resources.module').then(m => m.ResourceModule)
          },
          {
            path: `analytics`,
            data: {
              role: '2'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/analytics/analytics.module').then(m => m.AnalyticsModule)
          },
          {
            path: `tech-support`,
            data: {
              role: '4'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/tech-support/tech-support.module').then(m => m.TechSupportModule)
          },
          {
            path: `event-manage`,
            data: {
              role: '2'
            },
            canActivate: [AuthGuard],
            loadChildren: () => import('./admin-panel/pages/event-manage/event-manage.module').then(m => m.EventManageModule)
          },
          {
            path: 'event-not-found',
            component: EventNotFoundComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'event-not-found'
          }
        ]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'event-not-found'
      },
    ]
  },
  {
    path: 'event-not-found',
    component: EventNotFoundComponent
  },
  {
    path: 'event-not-found',
    component: EventNotFoundComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: EventNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
