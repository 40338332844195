import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuardUser implements CanActivate{
  slug: string;
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.slug = route.parent.params.dynamicPath;
    const token = sessionStorage.getItem('Token');

    if (token) {
      return true;
    } else {
      this.router.navigate([`${this.slug}/sign-in`]);
    }
  }
}
