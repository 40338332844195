export enum routes {
  DASHBOARD = '/secret/dashboard',
  ADD_URL = '/secret/add-url',
  BULK_IMPORT = '/secret/bulk-import',
  POLLS = "/secret/polls",
  ADD_POLLS = "/secret/polls/add",
  QUESTIONS = "/secret/questions",
  RESOURCES = "/secret/resources",
  ANALYTICS = "/secret/analytics",
  TECH_SUPPORT = "/secret/tech-support",
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/secret/sign-in',

  // MAIN ADMIN Management Route
  MAIN_ADMIN_MANAGE = '/secret/main-admin',

  // MAIN ADMIN Management Add Route
  MAIN_ADMIN_ADD = '/secret/main-admin/add',

  // EVENT Management Notification
  NOTIFICATION_EVENT = '/secret/event-manage/notification',

  // EVENT Management Functionaliy Selection
  FUNCTIONALITY = '/secret/event-manage/functionality'
}
