import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { Email, User } from '../../../../pages/auth/models';
import { AuthService, EmailService } from '../../../../pages/auth/services';
import { routes } from '../../../../consts';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  public user$: Observable<User>
  public emails$: Observable<Email[]>
  public routers: typeof routes = routes;
  slug: string;
  heading: string;

  constructor(
    private userService: AuthService,
    private emailService: EmailService,
    private router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.user$ = this.userService.getUser();
    this.emails$ = this.emailService.loadEmails();
    // this.slug = this._activatedRoute.snapshot.paramMap.get('dynamicPath');
    this.slug = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : null;
  }

  ngOnInit() {
    let location = this._activatedRoute["_routerState"].snapshot._root.value._routerState._root.children[0].value.params.dynamicPath
    let decodeToken = this.getDecodedAccessToken(sessionStorage.getItem("access-admin-token"));
    if (decodeToken) {
      if (decodeToken["user"]["data"]["role_id"] === '2' || decodeToken["user"]["data"]["role_id"] === '3' || decodeToken["user"]["data"]["role_id"] === '4' || decodeToken["user"]["data"]["role_id"] === '5') {
        switch(decodeToken["user"]["data"]["role_id"]) {
          case '2':
            this.heading = decodeToken["user"]["data"]["event_name"] + ' - ' + 'Event Manager';
            break;
          case '3':
            this.heading = decodeToken["user"]["data"]["event_name"] + ' - ' + 'Registration Manager';
            break;
          case '4':
            this.heading = decodeToken["user"]["data"]["event_name"] + ' - ' + 'Tech Support Manager';
            break;
          case '5':
            this.heading = decodeToken["user"]["data"]["event_name"] + ' - ' + 'QnA Manager';
            break;
        }
      } else {
        this.heading = '';
      }
    } else {
      // redirect to sign in page
      if (location) {
        sessionStorage.clear();
        this.router.navigate([`${location}/secret/sign-in`]);
      } else {
        sessionStorage.clear();
        this.router.navigate(['/secret/sign-in']);
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    // this.userService.signOut();
    sessionStorage.removeItem("access-admin-token");
    sessionStorage.removeItem("admin-id");
    sessionStorage.removeItem("admin-first-name");
    sessionStorage.removeItem("admin-last-name");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("event_id");
    if (!this.slug) {
      this.router.navigate([this.routers.LOGIN]);
    } else {
      this.router.navigate([`${this.slug}/${this.routers.LOGIN}`]);
    }
  }
}
