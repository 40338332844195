import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../shared/services/chat-service/chat.service';
import { AuthService } from '../user-panel/services/auth/auth.service';
import jwt_decode from "jwt-decode";
import { SharedService } from '../user-panel/auth/sign-in/sign-in/shared-service/shared.service';
import { DynamicRootSharedService } from './dynamic-root-shared-service/dynamic-root-shared.service';
import { ProgressSpinnerService } from '../shared-root/progress-spinner/progress-spiinner-service/progress-spinner.service';

@Component({
  selector: 'app-dynamic-root-user',
  templateUrl: './dynamic-root-user.component.html',
  styleUrls: ['./dynamic-root-user.component.scss']
})
export class DynamicRootUserComponent implements OnInit {
  location: string;
  imageUrl: string;
  isEventActive = true;
  checkImage: any;

  constructor(
    private _chatService: ChatService, 
    private _authService: AuthService, 
    private _router: Router, 
    private _activatedRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private _dynamicRootSharedService: DynamicRootSharedService,
    private _progressSpinnerService: ProgressSpinnerService
  ) { 
    this._chatService.getImageIfEventOff()
      .subscribe(response => {
        let location = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : null;
        if (response["off_event_url"]["slug"] === location) {
          this.imageUrl = response["off_event_url"]["url"];
          this.isEventActive = true;
          this.checkImageFn();
        }
      })
  }

  ngOnInit(): void {
    this.location = this._activatedRoute.snapshot.root.children[0]["params"]["dynamicPath"];
    if (this.location) {
      let decodeToken = this.getDecodedAccessToken(sessionStorage.getItem("Token"));
      if (decodeToken) {
        // CHECK SLUG IF EXISTS OR NOT
        this._progressSpinnerService.emitSpinnerState(true, 'Checking Event...');
        this._authService.getEventBySlug(this.location)
          .subscribe(response => {
            if (response["status_code"] === 200) {
              this._progressSpinnerService.emitSpinnerState(false);
              // SLUG IS PRESENT IN DB
              if (this.location !== decodeToken["user"]["data"]["event_slug"]) {
                this.isEventActive = false;
                sessionStorage.clear();
                this._router.navigate([`${this.location}/sign-in`]);
                this.checkSlug();
              } else {
                this._sharedService.shareEventData(response["data"]);
                if (response["data"]["is_completed"]) {
                  this.imageUrl = response["data"]["off_image_url"];
                  this.checkImageFn();
                  this.isEventActive = true;
                } else {
                  this.isEventActive = false;
                }
              }
            } else {
              // SLUG IS NOT PRESENT
              this._progressSpinnerService.emitSpinnerState(false);
              sessionStorage.clear();
              this.isEventActive = false;
              this._router.navigate([`${this.location}/event-not-found`]);
            }
          })
      } else {
        this.checkSlug();
      }
    }
    this.getEventSettings();
  }

  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  getEventSettings() {
    this._authService.getEventManagerSetting(this.location)
      .subscribe(response => {
        this._dynamicRootSharedService.emitData(response);
      })
  }

  checkSlug() {
    this._progressSpinnerService.emitSpinnerState(true, 'Checking Event...');
    this._authService.getEventBySlug(this.location)
      .subscribe(response => {
        if (response["status_code"] === 200) {
          this._progressSpinnerService.emitSpinnerState(false);
          this._sharedService.shareEventData(response["data"]);
          if (response["data"]["is_completed"]) {
            this.imageUrl = response["data"]["off_image_url"];
            this.checkImageFn();
            this.isEventActive = true;
          } else {
            this.isEventActive = false;
          }
        } else {
          this._progressSpinnerService.emitSpinnerState(false);
          this.isEventActive = false;
          this._router.navigate([`${this.location}/event-not-found`]);
        }
      }, (error) => {
        this._progressSpinnerService.emitSpinnerState(false);
          this.isEventActive = false;
          this._router.navigate([`${this.location}/event-not-found`]);
      })
  }

  checkImageFn() {
    this.checkImage = {
      'background-image': `url(${this.imageUrl})`, 
      'height': '100%',
      'width': '100%',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%'
    }
  }

}
