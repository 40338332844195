import { Route } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { UserSignInRootComponent } from './user-sign-in-root/user-sign-in-root.component';

export const signInRoutes: Route[] = [
    // {
    //     path : '',
    //     component: UserSignInRootComponent,
    //     children: [
            
    //     ]
    // }
    {
        path: '',
        component: SignInComponent
    }
];
