import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorUiUserComponent } from './error-ui-user/error-ui-user.component';
import { MatCardModule } from '@angular/material/card';


@NgModule({
    imports: [

        CommonModule,
        MatCardModule
    ],
    exports: [
        CommonModule,
        ErrorUiUserComponent
    ],
    declarations: [ErrorUiUserComponent]
})
export class SharedUserModule
{
}
