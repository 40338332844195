<mat-card class="errorDiv">
    <div class="col-md-12">
        <div class="row justify-content-between">
            <h2 class="col-md-11" style="color: rgb(255, 0, 0);">Error</h2>
            <div style="cursor: pointer" (click)="hideErrorComponent(false)">
                <strong>X</strong>
            </div>
        </div>
        <hr />
        <div style="line-height: 0.5rem; overflow-y: auto; overflow-x: hidden; min-height: 5vh; max-height: 30vh;">
            <ul *ngFor="let file of errorObj" style="list-style: none; line-height: 1.5rem;">
                <li *ngIf="file.type === 'application/pdf' && file.size >  10000000"><strong>{{file.name}}</strong> exceeds <strong>10 MB</strong> file size.</li>
                <li *ngIf="file.type !== 'application/pdf'"><strong>{{file.name}}</strong> is of type <strong>{{file.type}}</strong> and is not valid.</li>
                <hr />
            </ul>
        </div>
    </div>
</mat-card>