import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedTitleServiceService } from 'src/app/shared-root/shared-title-service/shared-title-service.service';
// import { userRoutes } from 'src/app/user-panel/consts/routes';
import { AuthService } from 'src/app/user-panel/services/auth/auth.service';
import { ChatService } from '../../../../shared/services/chat-service/chat.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { RegisterPopupComponent } from './register-popup/register-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from './shared-service/shared.service';
import { DynamicRootSharedService } from 'src/app/dynamic-root-user/dynamic-root-shared-service/dynamic-root-shared.service';
import { ProgressSpinnerService } from 'src/app/shared-root/progress-spinner/progress-spiinner-service/progress-spinner.service';
declare var gtag: any;
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;
  // public routers: typeof userRoutes = userRoutes;
  showError = true;
  deviceInfo = null;
  ipAddress: string;
  loginDate: string;
  loginTime: string;
  isLoading: boolean = false;
  openRegister: boolean = false;
  registerForm: FormGroup;
  showRegister: boolean = false;
  location: string;
  registerData: any;
  eventData: any;
  buttonColor: any;
  formPosition: any;
  backgroundImageLogin: any;
  moveToOtp: boolean = false;
  notRegisteredText: any;
  clickHereText: any;
  topLogosData: any;
  bottomLogosData: any;
  userId:any;
  apiResponse:any;

  constructor(
    private _formBuilder: FormBuilder,
    private _userAuthService: AuthService,
    private _router: Router,
    private _sharedTitleService: SharedTitleServiceService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private _dynamicRootSharedService: DynamicRootSharedService,
    private _progressSpinnerService: ProgressSpinnerService
  ) { 
    this._sharedService.currentEventData.subscribe(response => {
      if (response) {
        this.eventData = response;
      }
    })
    this._dynamicRootSharedService.currentDataFetch.subscribe(response => {
      if (response) {
        this.setColors(response);
      }
    })
  }

  setColors(data) {
    this.setButtonDynamic(data);
  }

  ngOnInit(): void { 
    this.location = this._activatedRoute.snapshot.root.children[0]["params"]["dynamicPath"];
    // this.checkSlug(this.location);
    this._userAuthService.getIP()
      .subscribe((res: any) => {
        this.ipAddress = res.ip;
      });

    this._sharedTitleService.setTitleFunction('Shobiz');

    this.signInForm = this._formBuilder.group({
      isAdmin: [""],
      isOtp: [""],
      otp: [""],
      email: [""],
      password: [""],
      emailClosed: ["", [Validators.email]]
      // firstName: ["", [Validators.required]],
      // lastName: ["", [Validators.required]]
    });

    this.registerForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern(/[a-zA-Z0-9]+@+[a-z]+\.[a-z]+/)]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]]
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let _date = new Date();
    let date = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    let month = (+_date.getMonth() + 1) < 10 ? "0" + (+_date.getMonth() + 1) : (+_date.getMonth() + 1);
    let year = _date.getFullYear();
    let time = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    this.getRegisteredFields();
  }

  getRegisteredFields() {
    this._progressSpinnerService.emitSpinnerState(true, 'Loading...');
    this._userAuthService.getRegisteredFields(this.location)
      .subscribe(response => {
        if (response["status_code"] === 200) {
          this._progressSpinnerService.emitSpinnerState(false);
          response["data"].forEach(val => {
            val["value"] = "";
          })
          // this.eventData = response["data"]
          this.registerData = response["data"]
        } else {
          this._progressSpinnerService.emitSpinnerState(false);
          this._snackBar.open(response["message"], "", { duration: 2000 });
        }
      }, (error) => {
        this._progressSpinnerService.emitSpinnerState(false);
        this._snackBar.open("Something went wrong", "", { duration: 2000 });
      })
  }

  // checkSlug(slug) {
  //   this._userAuthService.getEventBySlug(slug)
  //     .subscribe(response => {
  //       if (response["status_code"] === 200) {
  //         this._snackBar.open("Event Found Successfully", "", { duration: 2000 });
  //         // if (response["data"]["dynamic_fields"]) {
  //         //   response["data"]["dynamic_fields"].forEach(val => {
  //         //     val["value"] = "";
  //         //   })
  //         // }
  //         // this.eventData = response["data"];
  //       } else {
  //         this._router.navigate([`${slug}/event-not-found`]);
  //       }
  //     })
  // }

  patchValues(): void {
    this.signInForm.patchValue({
      isAdmin: 0,
      isOtp: 1,
      password: `"`
    })
  }

  loginClosed(event) {
    if (this.signInForm.get('emailClosed').value.trim() === "") {
      this._snackBar.open("Please enter email id to continue");
      return;
    }
    if (this.signInForm.controls['emailClosed'].errors?.email) {
      this._snackBar.open("Please enter correct email id to continue");
      return;
    }
    this._userAuthService.loginUser(this.location, this.signInForm.get('emailClosed').value)
      .subscribe(response => {
        console.log(response);
        this.apiResponse=response;
        this.userId=this.apiResponse.user_id;
        console.log(this.userId)
        if (response["status_code"] === 200 || response["status_code"] === 201) {
          this.moveToOtp = true;
          this._snackBar.open(response["message"], "", { duration: 2000 });
        } else {
          this._snackBar.open(response["message"], "", { duration: 2000 });
          this.moveToOtp = false;
        }
      }, (error) => {
        this._snackBar.open("Something went wrong", "", { duration: 2000 });
      })
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  verifyAndLogin(event) {
    if (!this.ipAddress) {
      this.ipAddress = "";
    }
    if (this.signInForm.controls["otp"].value.length === 0) {
      this._snackBar.open("Please enter passcode to continue", "", { duration: 2000 });
      return;
    }
    this._userAuthService.verifyAndLoginUser(this.location, this.signInForm.get('otp').value, this.deviceInfo.deviceType, this.deviceInfo.browser, this.deviceInfo.os, this.ipAddress,this.userId)
      .subscribe(response => {
        if(response["status_code"] === 200) {
          this._dynamicRootSharedService.emitWidgetDetails({
            deviceType: this.deviceInfo.deviceType,
            operatingSystem: this.deviceInfo.os, 
            ipAddress: this.ipAddress,
            browser: this.deviceInfo.browser
          })
          sessionStorage.setItem("Token", response["token"]);
          let decodeToken = this.getDecodedAccessToken(sessionStorage.getItem("Token"));
          gtag('config', 'UA-203003532-3', {
            'user_id': decodeToken["user"]["data"]["id"]
          });
          // gtag('event', 'userID', { 'user_id': decodeToken["user"]["data"]["id"] });
          gtag('event', 'login', {
            'event_label': 'Event Login Count',
            'event_category': `${this.location}`,
          });
          this._router.navigate([`${this.location}/stream`]);
        } else {
          this._snackBar.open(response["message"], "", { duration: 2000 });
        }
      }, (error) => {
        this._snackBar.open("Something went wrong", "", { duration: 2000 });
      })
  }

 
  login(event): void {
    event.preventDefault();
    this.patchValues();
    let _date = new Date();
    let date = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    let month = (+_date.getMonth() + 1) < 10 ? "0" + (+_date.getMonth() + 1) : (+_date.getMonth() + 1);
    let year = _date.getFullYear();
    this.loginDate = `${year}-${month}-${date}`;
    let time = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    this.loginTime = time;
    let isAdmin = this.signInForm.get('isAdmin').value;
    let isOtp = this.signInForm.get('isOtp').value;
    let email = this.signInForm.get('email').value;
    let password = this.signInForm.get('password').value;
    let otp = this.signInForm.get('otp').value;
    let type;
    if ((this.eventData.event_registration_type === 'open' || this.eventData.event_registration_type === 'closed') && (this.eventData.closed_registration_type === 'email') || this.eventData.closed_registration_type === '') {
      type = 'openOrCloseEmail';
    } else if (this.eventData.event_registration_type === 'closed' && this.eventData.closed_registration_type === 'emailMobileOtp') {
      type = 'closeWithOtp';
    }
    this.isLoading = true;
    if (!this.ipAddress) {
      this.ipAddress = "";
    }
    this._userAuthService.loginUserSimple(this.location, email, this.deviceInfo.deviceType, this.deviceInfo.browser, this.deviceInfo.os, this.ipAddress)
      .subscribe(response => {
        if (response["status_code"] === 200) {
          this._dynamicRootSharedService.emitWidgetDetails({
            deviceType: this.deviceInfo.deviceType,
            operatingSystem: this.deviceInfo.os,
            ipAddress: this.ipAddress,
            browser: this.deviceInfo.browser
          })
          sessionStorage.setItem("Token", response["token"]);
          let decodeToken = this.getDecodedAccessToken(sessionStorage.getItem("Token"));
          console.log(response)
          gtag('config', 'UA-203003532-3', {
            'user_id': decodeToken["user"]["data"]["id"]
          });
          gtag('event', 'userID', { 'user_id': decodeToken["user"]["data"]["id"] });
          // gtag('config', 'UA-203003532-3', {
          //   'user_id': response["users"]["data"].id
          // });
          // gtag('event', 'userID', { 'user_id': response["users"]["data"].id });
          this.isLoading = false;
          this._router.navigate([`${this.location}/stream`]);
          // ga('config', 'UA-202805356-1', {
          //   'user_id': response["users"]["data"].id
          // });
          // ga('event', 'login', {'dimension1': 'User'});
          // ga('set', 'userId', response["users"]["data"].id);
          // ga('send', 'event', 'authentication', 'user-id available');
        } else {
          this._snackBar.open(response["message"], "", { duration: 2000 });
          this.isLoading = false;
        }
        // <!-- added by Kunal bansal on 12 july 2021 start -->
      //   if (!response.users) {
      //     alert(response.message)
      //     this.isLoading = false;
      //   }
      //   else if (response.users.status_code == 200) {
      //     console.log("Login Response : ", response)
      //     console.log("NAVIGATOR CONNECTION : ", navigator)
      //     sessionStorage.setItem("Token", response.token);
      //     sessionStorage.setItem("user_id", response["users"]["data"].id);
      //     sessionStorage.setItem("userpersonalinfo", JSON.stringify(response.users.data));
      //     sessionStorage.setItem("userFirstName", response["users"]["data"].first_name);
      //     sessionStorage.setItem("userLastName", response["users"]["data"].last_name);
      //     sessionStorage.setItem("email", response["users"]["data"].email);
      //     gtag('config', 'UA-203003532-3', {
      //       'user_id': response["users"]["data"].id
      //     });
      //     gtag('event', 'userID', { 'user_id': response["users"]["data"].id });
      //     // ga('config', 'UA-202805356-1', {
      //     //   'user_id': response["users"]["data"].id
      //     // });
      //     // ga('event', 'login', {'dimension1': 'User'});
      //     // ga('set', 'userId', response["users"]["data"].id);
      //     // ga('send', 'event', 'authentication', 'user-id available');
      //     this.isLoading = false;
      //     this._router.navigate([`${this.location}/stream`]);
      //   }
      //   else {
      //     alert("Login failed")
      //     this.isLoading = false;
      //   }
      //   console.log(response)
      //   // end
      // }, (error) => {
      //   this.isLoading = false;
      }, (error) => {
        this._snackBar.open("Something went wrong", "", { duration: 2000 });
        this.isLoading = false;
      })
    // localStorage.setItem('user-access-token', "TEMP-TOKEN");
    // this._router.navigate([this.routers.STREAM]);
  }

  validate() {
    if (this.registerForm.controls['firstName'].invalid || this.registerForm.controls['firstName'].value.trim().length === 0) {
      this._snackBar.open("First Name is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['lastName'].invalid || this.registerForm.controls['lastName'].value.trim().length === 0) {
      this._snackBar.open("Last Name is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['email']?.errors?.required) {
      this._snackBar.open("Email is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['email']?.errors?.email) {
      this._snackBar.open("Email format is incorrect", "", { duration: 2000 });
      return false;
    }
    return true;
  }

  register() {
    // let validate = this.validate();
    // if (validate) {
      
    //   this._userAuthService.registerUser(this.registerForm.controls['firstName'].value, this.registerForm.controls['lastName'].value, this.registerForm.controls['email'].value)
    //     .subscribe(response => {
    //       console.log("RESPONSE : ", response)
    //       if (response["status_code"] === 200) {
    //         console.log("Registration code : ", response)
    //         sessionStorage.setItem("Token", response["token"]);
    //         sessionStorage.setItem("user_id", response["data"].id);
    //         sessionStorage.setItem("userpersonalinfo", JSON.stringify(response["data"]));
    //         sessionStorage.setItem("userFirstName", response["data"].first_name);
    //         sessionStorage.setItem("userLastName", response["data"].last_name);
    //         sessionStorage.setItem("email", response["data"].email);
    //         this.showRegister = false;
    //         this._router.navigate([`${this.location}/stream`]);
    //         this._snackBar.open(response["message"], "", { duration: 2000 });
    //       } else {
    //         this._snackBar.open(response["message"], "", { duration: 2000 });
    //       }
    //     }, (error) => {
    //       this._snackBar.open("Something went wrong", "", { duration: 2000 });
    //     })
    // }
  }

  showRegisterPopup() {
    this.showRegister = true;
  }

  closeRegisterPopup() {
    this.showRegister = false;
  }

  openRegisterPopup() {
    this._sharedService.shareRegisterData(this.registerData);
    this.openRegister = true;
  }

  closeRegister(data) {
    if (data) {
      this.openRegister = false;
    }
  }

  setButtonDynamic(data) {
    let colorData = JSON.parse(data["data"]["theme_color"]);
    let position = JSON.parse(data["data"]["login_background"]);

    this.topLogosData =JSON.parse(data["data"]["toplogos"]);
    this.topLogosData.logo_data.forEach(topLogo => {
      let topClass;
      if (+topLogo["left"] > 800) {
        topClass = {
          height: `${+topLogo["height"]*0.088}%`,
          left: "inherit",
          right: "10px",
          top: `${topLogo["top"]}px`,
          width: `${+topLogo["width"]*0.07}%`

        }
      } else {
        topClass = {
          height: `${+topLogo["height"]*0.088}%`,
          left: `${topLogo["left"]}px`,
          top: `${topLogo["top"]}px`,
          width: `${+topLogo["width"]*0.07}%`
        }
      }
      topLogo["topClass"] = topClass;
    })
    this.bottomLogosData =JSON.parse(data["data"]["bottomlogos"]);

    if (position) {
      this.formPosition = {
        'justify-content': position["loginData"]["login_position"],
        'display': 'flex',
        // 'height': '80vh',
        'height': '65vh',
        'align-items': 'center'
      }
      this.backgroundImageLogin = {
        'background-image': `url(${position["image"]})`, 
        'height': '100%',
        'width': '100%',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center'
      }
    }
    if (colorData) {
      colorData.forEach(val => {
        if (val["widgetName"] === "Login Button") {
          this.buttonColor = {
            'background-color': val["color"]
          }
        }
        if (val["widgetName"] === "Register Text (Not Registered)") {
          this.notRegisteredText = {
            'color': val["color"]
          }
        }
        if (val["widgetName"] === "Register Text (Click Here)") {
          this.clickHereText = {
            'color': val["color"]
          }
        }
      })
    }
  }

  // hideErrorUI(value: boolean): void {
  //   this.showError = false;
  // }

}




