import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedTitleServiceService } from './shared-root/shared-title-service/shared-title-service.service';
import { ChatService } from './shared/services/chat-service/chat.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './user-panel/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  flag = false;
  imageUrl: string;
  location: string;
  constructor(
    private titleService: Title, 
    private _sharedTitleService: SharedTitleServiceService
  ) {
    // let userID = sessionStorage.getItem("user_id");
    // let data = JSON.stringify({ user_id: userID });
    // let formData = new FormData();
    // formData.append("user_id", userID);
    // let userID = sessionStorage.getItem("user_id");
    //   let data = JSON.stringify({ user_id: userID });
    //   let formData = new FormData();
    //   formData.append("user_id", userID);
    // window.addEventListener("beforeunload", function (e) {
    //   e.preventDefault();
    //   _chatService.privateTechSupportMessage({
    //         sender_id: 60,
    //         reciver_id: 1,
    //         message: "Closing"
    //       })
    // });
  }

  // @HostListener('window:beforeunload', ['$event'])
  // public beforeunloadHandler(e) {
  //   e.preventDefault();
  //   this._chatService.privateTechSupportMessage({
  //     sender_id: 60,
  //     reciver_id: 1,
  //     message: "Closing"
  //   })
  //      e.returnValue = '';
  //   //  $event.returnValue = "Are you sure?";
  //   }

  // @HostListener('document:visibilitychange', ['$event'])
  // visibilitychange() {
  //   if (document.hidden) {
  //     let userID = sessionStorage.getItem("user_id");
  //     let data = JSON.stringify({ user_id: userID });
  //     navigator.sendBeacon('https://shobizdigital.in:9396/api/admin/logout', data);
  //   }
  // }

  ngOnInit() {
    this._sharedTitleService.currentTitle.subscribe(response => {
      if (response && response.length > 0) {
        this.titleService.setTitle(response);
      }
    })
  }
}
