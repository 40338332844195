import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/shared/services/chat-service/chat.service';
import { AuthService } from 'src/app/user-panel/services/auth/auth.service';

@Component({
  selector: 'app-user-sign-in-root',
  templateUrl: './user-sign-in-root.component.html',
  styleUrls: ['./user-sign-in-root.component.scss']
})
export class UserSignInRootComponent implements OnInit {

  location: string;
  imageUrl: string;
  isEventActive = true;
  checkImage: any;

  constructor(private _activatedRoute: ActivatedRoute, private _chatService: ChatService, private _authService: AuthService, private _router: Router) { 
    this.location = this._activatedRoute.snapshot.paramMap.get('dynamicPath');
    // console.log(this._activatedRoute.snapshot.paramMap.get('dynamicPath'));
    this._chatService.getImageIfEventOff()
      .subscribe(response => {
        // console.log("RESPONSE : ", response)
        this.imageUrl = response["off_event_url"];
        // console.log("IMAGE URL : ", this.imageUrl);
        this.isEventActive = true;
        this.checkImageFn();
      })
  }

  ngOnInit(): void {
    this.checkSlug();
  }

  checkSlug() {
    this._authService.getEventBySlug(this.location)
      .subscribe(response => {
        // console.log("Check SLUG RESPONSE : ", response)
        if (response["status_code"] === 200) {
          // console.log("Status Code is 200")
          // console.log("IS COMPLETED : ", response["data"]["is_completed"])
          if (response["data"]["is_completed"]) {
            // console.log("IS COMPLETED PROCEED")
            this.imageUrl = response["data"]["off_image_url"];
            // console.log("IMAGE URL : ", this.imageUrl)
            this.checkImageFn();
            this.isEventActive = true;
          } else {
            this.isEventActive = false;
          }
        } else {
          this.isEventActive = false;
          this._router.navigate([`${this.location}/event-not-found`]);
        }
      })
  }

  checkImageFn() {
    // console.log("IN CHECK FUNCTION")
    this.checkImage = {
      'background-image': `url(${this.imageUrl})`, 
      'height': '100%',
      'background-repeat': 'no-repeat'
    }
    // console.log("IMAGE OBJECT : ", this.checkImage)
  }

}
