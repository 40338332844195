import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicRootSharedService } from 'src/app/dynamic-root-user/dynamic-root-shared-service/dynamic-root-shared.service';
import { AuthService } from 'src/app/user-panel/services/auth/auth.service';
import { SharedService } from '../shared-service/shared.service';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent implements OnInit {

  registerForm: FormGroup;
  firstName: string;
  lastName: string;
  emailID: string;
  @Output() closeEvt = new EventEmitter();
  location: string;
  eventData: any;
  registeredData: any;
  registeringUser = false;
  registerButtonColor: any;
  closeRegisterPopupColor: any;
  headingColor: any;
  modalDiv: any;

  constructor(
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private _dynamicRootSharedService: DynamicRootSharedService
  ) {
    this._dynamicRootSharedService.currentDataFetch.subscribe(response => {
      if (response) {
        // console.log("SIGN IN EVENT SETTINGS : ", response)
        this.setColors(response);
      }
    })
  //   this._sharedService.currentEventData.subscribe(response => {
  //     if (response) {
  //       this.eventData = response;
  //       console.log("EVENT DATA : ", this.eventData);
  //     }
  //   })
  //   this._sharedService.currentRegisterData.subscribe(response => {
  //     if (response) {
  //       this.registeredData = response;
  //     }
  //   })
  }

  ngOnInit(): void {
    this.location = this._activatedRoute.snapshot.root.children[0]["params"]["dynamicPath"];
    this.registerForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern(/[a-zA-Z0-9]+@+[a-z]+\.[a-z]+/)]],
      firstName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      lastName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(10)]]
    });
    this.checkSlug();
  }

  setColors(data) {
    let themeColor = JSON.parse(data["data"]["theme_color"]);
    themeColor.forEach(val => {
      if (val["widgetName"] === 'Register Now Button') {
        this.registerButtonColor = {
          'background-color': val["color"]
        };
        this.headingColor = {
          'color': val["color"]
        }
        this.modalDiv = {
          'border': `2px solid ${val["color"]}`
        }
      }
      if (val["widgetName"] === "Closing Buttons") {
        this.closeRegisterPopupColor = {
          'background-color': val["color"]
        }
      }
    })
  }

  checkSlug() {
    this._authService.getEventBySlug(this.location)
      .subscribe(response => {
        // console.log("Check SLUG RESPONSE : ", response)
        if (response["status_code"] === 200) {
          this.eventData = response["data"];
          if (this.eventData["dynamic_fields"]) {
            this.eventData["dynamic_fields"].forEach(val => {
              val["value"] = "";
            })
          }
        } else {
          this._snackBar.open(response["message"], "", { duration: 2000 });
        }
      }, (error) => {
        this._snackBar.open("Something went wrong", "", { duration: 2000 });
      })
  }

  validate() {
    // console.log(this.registerForm.controls)
    if (this.registerForm.controls['firstName'].value.trim() === "") {
      this._snackBar.open("First Name is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls["firstName"].errors?.minlength) {
      this._snackBar.open("First Name must contain atleast 2 characters", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls["firstName"].errors?.maxlength) {
      this._snackBar.open("First Name must contain at max 10 characters", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['lastName'].value.trim() === "") {
      this._snackBar.open("Last Name is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls["lastName"].errors?.minlength) {
      this._snackBar.open("Last Name must contain atleast 2 characters", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls["lastName"].errors?.maxlength) {
      this._snackBar.open("Last Name must contain at max 10 characters", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['email']?.errors?.required) {
      this._snackBar.open("Email is required", "", { duration: 2000 });
      return false;
    }
    if (this.registerForm.controls['email']?.errors?.email) {
      this._snackBar.open("Email format is incorrect", "", { duration: 2000 });
      return false;
    }
    let flag = false;
    let lessSize = false;
    let greaterSize = false;
    this.eventData.dynamic_fields.forEach(val => {
      if ((val["name"].includes("Mobile") || (val["name"].includes("Phone"))) && val["value"].trim() === "") {
        flag = true;
      }
      if ((val["name"].includes("Mobile") || (val["name"].includes("Phone"))) && val["value"].trim().length < 6) {
        lessSize = true;
      }
      if ((val["name"].includes("Mobile") || (val["name"].includes("Phone"))) && val["value"].trim().length > 10) {
        greaterSize = true;
      }
    })
    if (flag) {
      this._snackBar.open("Mobile/Phone Number is required", "", { duration: 2000 });
      return false;
    }
    if (lessSize) {
      this._snackBar.open("Mobile/Phone Number must have minimum 6 characters", "", { duration: 2000 });
      return false;
    }
    if (greaterSize) {
      this._snackBar.open("Mobile/Phone Number must have maximum 10 characters", "", { duration: 2000 });
      return false;
    }

    // let isCorrect = false;
    // this.registeredData.forEach(val => {
    //   if (val["required"] && val["value"].trim() === "") {
    //     this._snackBar.open(`${val["name"]} is required`, "", { duration: 2000 });
    //     isCorrect = false;
    //     return;
    //   }
    // })
    // if (isCorrect === false) {
    //   return false;
    // }
    return true;
  }

  register() {
    if (!this.registeringUser) {
      let validate = this.validate();
      // console.log("VALIDATE : ", validate)
      if (validate) {
        let flag = false;
        let mobileNumber = null;
        if (this.eventData.dynamic_fields) {
          this.eventData.dynamic_fields.forEach(val => {
            if (val["name"] === "Mobile Number") {
              flag = true;
              mobileNumber = val["value"];
            }
          })
        }
        let data;
        if (flag) {
          data = {
            email: this.registerForm.get('email').value,
            first_name: this.registerForm.get('firstName').value,
            last_name: this.registerForm.get('lastName').value,
            mobile_number: mobileNumber,
            meta_data: []
          };
        } else {
          data = {
            email: this.registerForm.get('email').value,
            first_name: this.registerForm.get('firstName').value,
            last_name: this.registerForm.get('lastName').value,
            meta_data: []
          };
        }
        if (this.eventData["dynamic_fields"]) {
          this.eventData["dynamic_fields"].forEach(val => {
            if (val["name"] !== "Mobile Number")
            data.meta_data.push({
              "meta_key": val["name"],
              "meta_value": val["value"]
            })
          })
        }
        // console.log("REGISTRATION DATA : ", data)
        this.registeringUser = true;
        this._authService.registerUser(this.location, data)
          .subscribe(response => {
            // console.log("RESPONSE : ", response)
            if (response["status_code"] === 200) {
              this.registeringUser = false;
              // console.log("Registration code : ", response)
              sessionStorage.setItem("Token", response["token"]);
              if (this.eventData["event_registration_type"] === 'open') {
                this._router.navigate([`${this.location}/stream`]);
              } else if (this.eventData["event_registration_type"] === 'closed') {
                this._snackBar.open("Please check email", "", { duration: 2000 });
                this.closeEvent();
              }
            } else {
              this.registeringUser = false;
              this._snackBar.open(response["message"], "", { duration: 2000 });
            }
          }, (error) => {
            this.registeringUser = false;
            this._snackBar.open("Something went wrong", "", { duration: 2000 });
            this.closeEvent();
          })
      }
    }
  }

  clearRegisterForm() {

  }

  closeEvent(type='button') {
    if (type === 'button') {
      this.closeEvt.emit(true);
    } else {
      if (!this.registeringUser) {
        this.closeEvt.emit(true);
      }
    }
  }

  preserveNumber(event, name) {
    if (name.includes("Mobile") || name.includes("Phone")) {
      var k;
      k = event.charCode;
      return((k > 47 && k < 58)); 
    }
  }

}
