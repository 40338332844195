import { Injectable } from "@angular/core";
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ChatService {

    socket;
    constructor() {
        this.socket = io(environment.apiUrl);
    }

    joinRoom(data) {
        let socketId;
        this.socket.on('connect', () => {
            socketId = this.socket.id;
            data["id"] = socketId
            if (data["userid"]) {
                // console.log("DATA : ", data);
                this.socket.emit('join', data);
            }
        });
    }

    newUserJoined() {
        let observable = new Observable<{ user: String, message: String, userid: String }>(observer => {
            this.socket.on('new user joined', (data) => {
                if (data["userid"]) {
                    // console.log("NEW USER JOINED ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::", data)
                    observer.next(data);
                }
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    // shobiz pole module real time updation added by Kunal bansal on 15 july 2021 start
    Poleactive() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('poll_is_active_now', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    QnA() {
        let observable = new Observable<{ qna_data: any }>(observer => {
            this.socket.on('qna', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    // Event added by Kunal bansal on 20 july 2021 for disabling poll and poll button
    Poleisnotactive() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('poll_is_not_active', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

    // Event added by Kunal bansal on 20 july 2021 for disabling poll only
    Poleisnotvisible() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('poll_is_not_visible', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

    // Event added by Kunal bansal on 20 july 2021 for showing poll result
    Showpollresult() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('show_poll_result_now', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

      // Event added by Kunal bansal on 21 july 2021 for hiding  poll result
      Hidepollresult() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('hide_poll_now', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

    Polevisible() {
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('poll_is_visible_now', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

     // Event added by Kunal bansal on 21 july 2021 for update video url everytime change from admin
    showstreamurl(){
        let observable = new Observable<{ poll_id: String }>(observer => {
            this.socket.on('show_stream_url', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

    leaveRoom(data) {
        this.socket.emit('leave', data);
    }

    userLeftRoom() {
        let observable = new Observable<{ user: String, message: String }>(observer => {
            this.socket.on('left room', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    // private chat module added by Kunal bansal on 11 july 2021 start
    privatemessage(data) {
        this.socket.emit('privatechat', data);
    }
    // end

    // private chat module added by Kunal bansal on 11 july 2021 start
    privatemessagereceived() {
        let observable = new Observable<{ user: String, message: String, sender_id: String, reciver_id: String }>(observer => {
            this.socket.on('messagereceived', (data) => {
                // console.log(data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;

    }
    // end

    sendMessage(data) {
        this.socket.emit('message', data);
    }

    newMessageReceived() {
        let observable = new Observable<{ user: String, message: String, sender_id: String }>(observer => {
            this.socket.on('new message', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    // socket to get image link from when toggle from admin added on 22 july 2021 by Kunal bansal start 
    Imagetoggle() {
        let observable = new Observable<{ user: String, message: String, sender_id: String }>(observer => {
            this.socket.on('show_sessio_nurl', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    // end

    privateTechSupportMessage(data) {
        // console.log("EMITTING TECH DATA : ", data)
        this.socket.emit('techMessage', data);
    }

    privateTechSupportMessageReceived() {
        let observable = new Observable<{ user: string, message: string, sender_id: string, reciver_id: string }>(observer => {
            this.socket.on('technewmessage', (data) => {
                // console.log("TECH NEW MESSAGE : : : : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getLiveCount() {
        let observable = new Observable<{ loggedInuser: number }>(observer => {
            this.socket.on('LoggedInCount', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getImageIfEventOff() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('offEventUrl', (data) => {
                // console.log("IMAGE EVENT OFF : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getChatWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_chat', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getAskSpeakerWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_ask_speaker', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getAgendaWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_agenda', (data) => {
                // console.log("AGENDA WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getResourceWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_resources', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getTechWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_tech_support', (data) => {
                // console.log("TECH SUPPORT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getPollWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_poll', (data) => {
                // console.log("POLL WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getPhotoWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_photobooth', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getFeedbackWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_feedback', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getPopupFeedbackWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_popup_feedback', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getNotificationkWidgetSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_notification', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getAttendeeListSettings() {
        let observable = new Observable<{ off_event_url: string }>(observer => {
            this.socket.on('widget_event_setting_attendee_list', (data) => {
                // console.log("RESOURCES WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }
    
    getNotification() {
        let observable = new Observable<any>(observer => {
            this.socket.on('notificationEvent', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getBlink() {
        let observable = new Observable<any>(observer => {
            this.socket.on('blink_widget', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    updatePopupFeedbackURL(data) {
        console.log("URL DATA : : :  ", data)
        this.socket.emit('activateFeedbackURL', data);
    }

    deactivatePopupFeedbackURL(data) {
        console.log("URL DATA : : :  ", data)
        this.socket.emit('deactivateFeedbackURL', data);
    }

    getPopupFeedbackURL() {
        let observable = new Observable<any>(observer => {
            this.socket.on('URLActivated', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

    getPopupFeedbackURLRemoved() {
        let observable = new Observable<any>(observer => {
            this.socket.on('URLDeactivated', (data) => {
                // console.log("CHAT WIDGET SETTINGS : ", data)
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });
        return observable;
    }

}