import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resource-video-play',
  templateUrl: './resource-video-play.component.html',
  styleUrls: ['./resource-video-play.component.scss']
})
export class ResourceVideoPlayComponent implements OnInit {

  resourceURL: string;

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.resourceURL = sessionStorage.getItem("resource-url");
    if (!!this.resourceURL === false) {
      window.close();
    }
  }

}
