import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HeaderModule } from './header/header.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsMenuComponent } from './ui-elements/settings-menu/settings-menu.component';
import { DateMenuComponent } from './ui-elements/date-menu/date-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { ErrorUiAdminComponent } from './error-ui-admin/error-ui-admin.component';
import { MaterialModule } from './modules/material/material.module';
import { ProgressSpinnerComponent } from 'src/app/shared-root/progress-spinner/progress-spinner.component';


@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    ErrorUiAdminComponent,
    ProgressSpinnerComponent
  ],
  imports: [
    HeaderModule,
    RouterModule,
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    HeaderModule,
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    ErrorUiAdminComponent,
    LayoutComponent,
    ProgressSpinnerComponent
  ]
})
export class SharedModule { }
