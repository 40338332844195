import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private setRegisterData = new BehaviorSubject('');
  currentRegisterData = this.setRegisterData.asObservable();

  private setEventData = new BehaviorSubject('');
  currentEventData = this.setEventData.asObservable();

  constructor() { }

  shareRegisterData(data) {
    // console.log("REGISTER SERVICE DATA : : : : :  ", data)
    this.setRegisterData.next(data);
  }

  shareEventData(data) {
    // console.log("EVENT SERVICE DATA : : : : :  ", data)
    this.setEventData.next(data);
  }

}
