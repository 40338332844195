<mat-toolbar class="header">

  <div class="header__title">
    <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
    </button>
    <h6 class="header__title-text">Shobiz</h6>
  </div>

  <div class="header-toolbar" style="width: 100%; justify-content: space-between;">
    <!-- <a
      mat-flat-button
      class="unlock-button"
      href="https://flatlogic.com/templates/angular-material-admin-full/demo"
      target="_blank"
    >Unlock Full Version</a> -->
    <!-- <app-search></app-search> -->
    <!-- <app-notifications></app-notifications> -->
    <!-- <app-email [emails]="emails$ | async"></app-email> -->
    <div style="width: 100%; display: flex; justify-content: center;">
      <h3 style="color: white;">{{heading}}</h3>
    </div>
    <app-user (signOut)="signOut()"></app-user>
  </div>

</mat-toolbar>
