import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";
import { DashboardService } from '../../services';
import {
  DailyLineChartData,
  PerformanceChartData,
  ProjectStatData,
  RevenueChartData,
  ServerChartData,
  SupportRequestData,
  VisitsChartData
} from '../../models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  public dailyLineChartData$: Observable<DailyLineChartData>;
  public performanceChartData$: Observable<PerformanceChartData>;
  public revenueChartData$: Observable<RevenueChartData>;
  public serverChartData$: Observable<ServerChartData>;
  public supportRequestData$: Observable<SupportRequestData[]>;
  public visitsChartData$: Observable<VisitsChartData>;
  public projectsStatsData$: Observable<ProjectStatData>;
  dashboardType = '';
  fullName = '';
  eventDate = '';
  eventTime = '';
  eventName = '';
  slug: string;

  constructor(private service: DashboardService, private _activatedRoute: ActivatedRoute, private _router: Router) {
    this.dailyLineChartData$ = this.service.loadDailyLineChartData();
    this.performanceChartData$ = this.service.loadPerformanceChartData();
    this.revenueChartData$ = this.service.loadRevenueChartData();
    this.serverChartData$ = this.service.loadServerChartData();
    this.supportRequestData$ = this.service.loadSupportRequestData();
    this.visitsChartData$ = this.service.loadVisitsChartData();
    this.projectsStatsData$ = this.service.loadProjectsStatsData();
  }
  ngOnInit() {
    let decodeToken = jwt_decode(sessionStorage.getItem("access-admin-token"));
    let firstName = decodeToken["user"]["data"]["first_name"];
    let lastName = decodeToken["user"]["data"]["last_name"];
    this.fullName = (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
    this.eventDate = decodeToken["user"]["data"]["event_date"];
    this.eventTime = decodeToken["user"]["data"]["event_time"];
    this.eventName = decodeToken["user"]["data"]["event_name"];
    // console.log("DECODED TOKEN : ", decodeToken);
    // console.log("DECODED TOKEN ROLE ID : ", decodeToken["user"]["data"]["role_id"], typeof decodeToken["user"]["data"]["role_id"]);
    switch(decodeToken["user"]["data"]["role_id"]) {
      case 1:
      case '1':
        this.dashboardType = 'Main Admin Dashboard';
        break;
      case 2:
      case '2':
        this.dashboardType = 'Event Manager Dashboard';
        break;
      case 3:
      case '3':
        this.dashboardType = 'Registration Manager Dashboard';
        break;
      case 4:
      case '4':
        this.dashboardType = 'Tech Support Dashboard';
        break;
      case 5:
      case '5':
        this.dashboardType = 'QnA Panel Dashboard';
        break;
    }
    // console.log("DASHBOARD TYPE : ", this.dashboardType)
  }
}
