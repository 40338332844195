<app-layout>
  <div class="custom-page-header" role="heading">
    <h1>{{dashboardType}}</h1>
    <!-- <button mat-flat-button color="warn">Latest Reports</button> -->
  </div>
  <div *ngIf="dashboardType !== 'Main Admin Dashboard'">
    <mat-card>
      <h5 class="mb-4">Welcome {{fullName}}</h5>
      <p class="mb-4" style="font-weight: bold;">Event Name: {{eventName}}</p>
      <p class="mb-4" style="font-weight: bold;">Event Date: {{eventDate | date: 'dd/MM/yyyy'}}</p>
      <p class="mb-4" style="font-weight: bold;">Event Time: {{eventTime}}</p>
    </mat-card>
  </div>

  <!-- <div class="charts-wrapper">
    <div class="chart">
      <app-visits-chart [visitsChartData]="visitsChartData$ | async"></app-visits-chart>
    </div>
    <div class="chart">
      <app-performance-chart [performanceChartData]="performanceChartData$ | async"></app-performance-chart>
    </div>
    <div class="chart">
      <app-server-chart [serverChartData]="serverChartData$ | async"></app-server-chart>
    </div>
    <div class="chart">
      <app-revenue-chart [revenueCharData]="revenueChartData$ | async"></app-revenue-chart>
    </div>
  </div>

  <app-daily-line-chart [dailyLineChartData]="dailyLineChartData$ | async"></app-daily-line-chart>

  <app-project-stat-chart [projectsStatsData]="projectsStatsData$ | async"></app-project-stat-chart>

  <app-support-requests [supportRequestData]="supportRequestData$ | async"></app-support-requests>

  <app-footer></app-footer> -->
  <!-- <app-progress-spinner></app-progress-spinner> -->
</app-layout>
