import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { routes } from '../../../consts';

@Injectable()
export class AuthTempQnAGuard implements CanActivate{
  public routers: typeof routes = routes;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = sessionStorage.getItem('access-qna-token');

    if (token) {
      return true;
    } else {
      this.router.navigate(['/qna']);
    }
  }
}
