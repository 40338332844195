import { OnInit, Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
// import { userRoutes } from '../../consts/routes';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, AfterViewInit {

  // @ViewChild('days') days: ElementRef;
  // public routers: typeof userRoutes = userRoutes;
  deadline;
  timeinterval;
  days;
  hours;
  minutes;
  seconds;
  isCounterRunning: boolean = true;

  constructor(private _router: Router) { }

  ngOnInit(): void {
    // let date = 'August 24 2021 10:00:00 +05:30';
    // this.deadline = new Date(Date.parse(date) + 0 * 24 * 60 * 60 * 1000);
    // this.initializeClock(this.deadline);
  }

  ngAfterViewInit() {
    var countDownDate = new Date("September 02 2021 10:00:00 +05:30").getTime();
    this.getTime(countDownDate)
    // console.log("DEADLINE : ", this.deadline)
  }

  getTime(countDownDate) {
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
    
      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.days = this.days < 10 ? '0' + this.days : this.days;
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
    
      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        this.isCounterRunning = false;
      }
    }, 1000);
  }

  redirectToSignIn() {
    // this._router.navigate([this.routers.LOGIN]);
  }

}
