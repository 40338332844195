<div class="bg">
    <div id="wrapper" class="wrapper screen-scroll">
        <div class="header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="logo-left"><img src="../../../../assets/images/left_logo.png" class="img-fluid"></div>
                        <!-- <div class="logo-right"><img src="images/right_logo.png" class="img-fluid"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid contenttext">
            <h1>Librarian Forum India 2021</h1>
            <h2>Role of the librarians in promoting research<br>integrity and overall institutional profile</h2>
            <h4>September 02 2021, 10:30 AM IST</h4>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div id="clockdiv" class="coundownbox text-center" *ngIf="isCounterRunning">
                        <div>
                            <span class="days">{{days}}</span>
                            <div class="smalltext">DAYS</div>
                        </div>
                        <div>
                            <span class="hours">{{hours}}</span>
                            <div class="smalltext">HOURS</div>
                        </div>
                        <div>
                            <span class="minutes">{{minutes}}</span>
                            <div class="smalltext">MINUTES</div>
                        </div>
                        <div>
                            <span class="seconds">{{seconds}}</span>
                            <div class="smalltext">SECONDS</div>
                        </div>
                    </div>
                    <div id="clockdiv" *ngIf="!isCounterRunning" class="coundownbox text-center">
                        <button (click)="redirectToSignIn()" class="redirect-btn">Join</button>
                    </div>
                </div>
            </div>
        </div>



    </div>

    <div id="warning-message">
        <p>This website is only viewable in landscape mode. Rotate Your Mobile</p>
    </div>
    <!-- <script src="js/jquery-latest.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script type="text/javascript">function getTimeRemaining(endtime) {
            var t = Date.parse(endtime) - Date.parse(new Date());
            var seconds = Math.floor((t / 1000) % 60);
            var minutes = Math.floor((t / 1000 / 60) % 60);
            var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
            var days = Math.floor(t / (1000 * 60 * 60 * 24));
            return {
                'total': t, 'days': days, 'hours': hours, 'minutes': minutes, 'seconds': seconds
            }
                ;
        }

        function initializeClock(id, endtime) {
            var clock = document.getElementById(id);
            var daysSpan = clock.querySelector('.days');
            var hoursSpan = clock.querySelector('.hours');
            var minutesSpan = clock.querySelector('.minutes');
            var secondsSpan = clock.querySelector('.seconds');
            function updateClock() {
                var t = getTimeRemaining(endtime);
                daysSpan.innerHTML = t.days;
                hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
                if (t.total <= 0) {
                    clearInterval(timeinterval);
                }
            }
            updateClock();
            var timeinterval = setInterval(updateClock, 1000);
        }

        var deadline = new Date(Date.parse(new Date('August 24 2021 10:00:00 +05:30')) + 00 * 24 * 60 * 60 * 1000);
        initializeClock('clockdiv', deadline);
    </script> -->
</div>