import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { routes } from '../../../consts';
import jwt_decode from "jwt-decode";

@Injectable()
export class AuthGuard implements CanActivate{
  public routers: typeof routes = routes;
  location: string;

  constructor(private router: Router, private _activatedRoute: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // this.location = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : null;
    this.location = route["_routerState"].root._routerState._root.value._routerState._root.value.children[0].params.dynamicPath;
    const token = sessionStorage.getItem('access-admin-token');
    if (!token) {
      if (this.location) {
        this.router.navigate([`${this.location}${this.routers.LOGIN}`]);
      } else {
        this.router.navigate([this.routers.LOGIN]);
      }
    }
    let decodeToken = jwt_decode(sessionStorage.getItem("access-admin-token"));
    if (route.data.role && route.data.role.indexOf(decodeToken["user"]["data"]["role_id"]) === -1) {
      if (this.location) {
        this.router.navigate([`${this.location}${this.routers.LOGIN}`]);
      } else {
        this.router.navigate([this.routers.LOGIN]);
      }
    } else {
      if (token) {
        return true;
      } else {
        if (this.location) {
          this.router.navigate([`${this.location}${this.routers.LOGIN}`]);
        } else {
          this.router.navigate([this.routers.LOGIN]);
        }
      }  
    }
  }
}
