import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerService } from './progress-spiinner-service/progress-spinner.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {

  flag = false;
  label = '';

  constructor(
    private _progressSpinnerService: ProgressSpinnerService
  ) { 
    this._progressSpinnerService.newFetch.subscribe(response => {
      if (response) {
        this.flag = response["flag"];
        this.label = response["label"];
      }
    })
  }

  ngOnInit(): void {
  }

}
