import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { routes } from '../../../../consts';
import { SharedTitleServiceService } from 'src/app/shared-root/shared-title-service/shared-title-service.service';
import { AuthService } from 'src/app/admin-panel/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgressSpinnerService } from 'src/app/shared-root/progress-spinner/progress-spiinner-service/progress-spinner.service';

declare var gtag: any;

export interface IAuth {
  isAdmin: string;
  isOtp: string;
  email: string;
  password: string;
}

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {
  
  public todayDate: Date = new Date();
  public routers: typeof routes = routes;
  showError = false;
  errorList: any;
  panelType: string;
  slug: string = "";

  constructor(
    private service: AuthService,
    private router: Router,
    private _sharedTitle: SharedTitleServiceService,
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _progressSpinnerService: ProgressSpinnerService
  ) {
  }

  ngOnInit() {
    // console.log(this._activatedRoute.snapshot.root.children[0]["params"]["dynamicPath"]);
    this.slug = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : '';
    // console.log("Dynamic Component Location : ", this.slug);
    this._sharedTitle.setTitleFunction('Shobiz Admin');
    if (location.pathname.includes('qna')) {
      this.panelType = "Shobiz QnA Panel";
    } else if (location.pathname.includes('secret/sign-in')) {
      this.panelType = "Shobiz Admin Panel";
    }
    // if (this.slug) {
    //   this._progressSpinnerService.emitSpinnerState(true, 'Checking Slug (from Auth)...');
    //   this._authService.getEventBySlug(window.location.pathname.split("/")[1])
    //     .subscribe(response => {
    //       console.log("RESPONSE : ", response)
    //       if (response["status_code"] === 200) {
    //         this._progressSpinnerService.emitSpinnerState(false);
    //         this.slug = window.location.pathname.split("/")[1];
    //       } else {
    //         this._progressSpinnerService.emitSpinnerState(false);
    //         this.router.navigate([`${window.location.pathname.split("/")[1]}/secret/event-not-found`]);
    //       }
    //     })
    // }
  }

  public sendLoginForm(data: IAuth): void {
    // CHECK IF ADMIN PANEL
    let url = this.slug ? this.slug + this.routers.DASHBOARD : this.routers.DASHBOARD;
    // if (this.panelType === "Shobiz Admin Panel") {
      // console.log("DATA : ", data)
      this._progressSpinnerService.emitSpinnerState(true, 'Logging In...');
      this._authService.signIn(data.isAdmin, data.isOtp, data.email, data.password, this.slug)
        .subscribe(response => {
          // console.log("RESPONSE : ", response)
          if (response["auth"] === true) {
            this._progressSpinnerService.emitSpinnerState(false);
            sessionStorage.setItem("access-admin-token", response["token"]);
            // sessionStorage.setItem("admin-first-name", response["users"]["data"]["first_name"]);
            // sessionStorage.setItem("admin-last-name", response["users"]["data"]["last_name"]);
            // sessionStorage.setItem("admin-id", response["users"]["data"]["id"]);
            // sessionStorage.setItem("role", response["users"]["data"]["role_id"]);
            // if (response["users"]["data"]["event_id"] !== null && response["users"]["data"]["event_id"] !== undefined) {
            //   sessionStorage.setItem("event_id", response["users"]["data"]["event_id"]);
            // }
            this.router.navigate([url]);
          } else if (response["auth"] === false) {
            this._progressSpinnerService.emitSpinnerState(false);
            this._snackBar.open(response["message"], "", { duration: 2000 })
            this.showErrorsToUsers(response);
          } else {
            this._progressSpinnerService.emitSpinnerState(false);
            this._snackBar.open(response["message"], "", { duration: 2000 })
          }
        },
        error => {
          // console.log(error);
          this._progressSpinnerService.emitSpinnerState(false);
          this.showErrorsToUsers(error);
      })
    // } 
    // else if (this.panelType === "Shobiz QnA Panel") {
    //   this._authService.signInTempQna(data.email, data.password, 3)
    //     .subscribe(response => {
    //       console.log("RESPONSE : ", response)
    //       if (response["status_code"] === 200) {
    //         this.isLoading = false;
    //         sessionStorage.setItem("access-qna-token", response["token"]);
    //         this.router.navigate(['/qna-dashboard']);
    //       } else if (response["auth"] === false) {
    //         this.isLoading = false;
    //         this._snackBar.open(response["message"], "", { duration: 2000 })
    //         this.showErrorsToUsers(response);
    //       } else {
    //         this._snackBar.open(response["message"], "", { duration: 2000 })
    //         this.isLoading = false;
    //       }
    //     }, 
    //     error => {
    //       console.log(error);
    //       this.isLoading = false;
    //       this.showErrorsToUsers(error);
    //   })
    // }
    // this.service.login();

    // this.router.navigate([this.routers.DASHBOARD]).then();
  }

  public sendSignForm(): void {
    // this.service.sign();

    // this.router.navigate([this.routers.DASHBOARD]).then();
  }

  showErrorsToUsers(errorObj): void {
    this.errorList = errorObj;
    this.showError = true;
  }

  hideErrorUI(value: boolean): void {
    this.showError = false;
  }
}
