import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl1 = environment.apiUrl;

  constructor(private _httpClient: HttpClient) { }

  signIn(isAdmin: string, isOtp: string, email: string, password: string, deviceType: string, browser: string, os: string, ipAddress: string, role_id: string): Observable<any> {
    const body = new HttpParams()
      .set('isAdmin', isAdmin)
      .set('isOtp', isOtp)
      .set("role_id", role_id)
      .set('email', email)
      .set('password', password)
      .set('device_type', deviceType)
      .set('browser', browser)
      .set('ip', ipAddress)
      .set('os', os);
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
    // { Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1c19jb2RlIjoyMDAsInN0YXR1cyI6InN1Y2Nlc3MiLCJkYXRhIjp7ImlkIjoxLCJyb2xlX2lkIjoxLCJmaXJzdF9uYW1lIjoiYWRtaW4iLCJsYXN0X25hbWUiOiJhZG1pbiIsInVzZXJuYW1lIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJEVxVUVISjEyMWpwZVBQU1FZcFNqTk82VFA5V2ZCWEhnblh4VFRnU1VmTnIvbFE5Sm1INk9tIiwicHJvZmlsZV9pbWciOm51bGwsImNyZWF0ZWRBdCI6IjIwMjAtMDYtMThUMTM6NTQ6MDcuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjAtMDYtMThUMTM6NTQ6MDcuMDAwWiJ9fSwiaWF0IjoxNjI1ODE1MTU0LCJleHAiOjE2MjU4NTExNTR9.BeS5LX8fuZUH__VV8NMsY47wqI-mmPQD2GEDjgNz86k' },
    // { 'Content-Type', }
    return this._httpClient.post(`${this.apiUrl1}api/auth/login`, body, {
      headers: httpHeaders
    })
      .pipe(catchError(this.errorHandler));
  }

  getEventBySlug(slug) {
    return this._httpClient.get(`${this.apiUrl1}api/admin/getEventBySlug/${slug}`)
      .pipe(catchError(this.errorHandler));
  }

  getIP(): Observable<any> {
    return this._httpClient.get("http://api.ipify.org/?format=json");
  }

  registerUser(slug, data) {
    return this._httpClient.post(`${this.apiUrl1}api/auth/userregistartion?slug=${slug}`, data)
      .pipe(catchError(this.errorHandler));
  }

  getReceiverId(slug) {
    return this._httpClient.get(`${this.apiUrl1}api/admin/getAdminTechData?slug=${slug}`)
      .pipe(catchError(this.errorHandler));
  }

  getRegisteredFields(slug) {
    return this._httpClient.get(`${this.apiUrl1}api/auth/getregistartionfields?slug=${slug}`)
      .pipe(catchError(this.errorHandler));
  }

  loginUserSimple(slug, email, deviceType, browser, operatingSystem, ip) {
    return this._httpClient.post(`${this.apiUrl1}api/auth/userlogin?slug=${slug}`, { email: email, device_type: deviceType, browser: browser, operating_system: operatingSystem, ip: ip})
    .pipe(catchError(this.errorHandler));    
  }

  loginUser(slug, email ) {
    return this._httpClient.post(`${this.apiUrl1}api/auth/userlogin?slug=${slug}`, { email: email  })
      .pipe(catchError(this.errorHandler));
  }

  verifyAndLoginUser(slug, otp, deviceType, browser, operatingSystem,ip,user_id) {
    return this._httpClient.post(`${this.apiUrl1}api/auth/verifyOtpForLogin?slug=${slug}`, { otp: otp, device_type: deviceType, browser: browser, operating_system: operatingSystem, ip: ip,user_id: user_id})
      .pipe(catchError(this.errorHandler));
  }

  getEventManagerSetting(slug) {
    return this._httpClient.get(`${this.apiUrl1}api/userpanel/getUserEventSettings?slug=${slug}`)
      .pipe(catchError(this.errorHandler));
  }

  uploadLogos(slug, logoPosition, logoType, logoData,inputFile) {
    return this._httpClient.put(`${this.apiUrl1}api/auth/uploadMultipleLogos?slug=${slug}`, { logo_position: logoPosition, logo_type: logoType, logo_data: logoData, inputFile: inputFile,})
      .pipe(catchError(this.errorHandler));
  }

  deleteLogo(slug, logoPosition, logoType, logoData) {
    return this._httpClient.put(`${this.apiUrl1}api/auth/deleteMultipleLogos?slug=${slug}`, { logo_position: logoPosition, logo_type: logoType, logo_data: logoData})
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
