import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '../../consts/routes';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  routes = routes;
  public isOpenUiElements = false;
  role: number;
  localRoutes: any;
  url: string;
  route: string;

  constructor(private _router: Router) {

  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }

  ngOnInit() {
    this.url = window.location.pathname.split("/")[1];
    this.route = window.location.pathname;
    let decodeToken = jwt_decode(sessionStorage.getItem("access-admin-token"));
    this.role = +decodeToken["user"]["data"]["role_id"];
    let temp = [];
    // this.routes.forEach(val => {
    //   Object.entries(val).forEach(val => {
    //     val[1] = this.url + val[1];
    //     console.log(val)
    //     temp.push(val)
    //   })
    //   // let val = Object.entries(val)[1];
    //   // Object.entries(val)[1] = this.url.concat(Object.entries(val)[1])
    //   // this.url + Object.entries(val)
    //   console.log(temp);
    // })
    // this.routes = temp;
  }

  navigate(route) {
    switch(route) {
      case 'admin-dashboard':
        this._router.navigate([`/secret/dashboard`]);
        break;
      case 'main-panel':
        this._router.navigate(['/secret/main-admin']);
        break;
      case 'dashboard':
        this._router.navigate([`${this.url}/secret/dashboard`]);
        break;
      case 'notification':
        this._router.navigate([`${this.url}/secret/event-manage/notification`]);
        break;
      case 'report':
        this._router.navigate([`${this.url}/secret/event-manage/reports`]);
        break;
      case 'functionality':
        this._router.navigate([`${this.url}/secret/event-manage/functionality`]);
        break;
      case 'add-url':
        this._router.navigate([`${this.url}/secret/add-url`]);
        break;
      case 'polls':
        this._router.navigate([`${this.url}/secret/polls`]);
        break;
      case 'resources':
        this._router.navigate([`${this.url}/secret/resources`]);
        break;
      case 'analytics':
        this._router.navigate([`${this.url}/secret/analytics`]);
        break;
      case 'tech_support':
        this._router.navigate([`${this.url}/secret/tech-support`]);
        break;
      case 'bulk-import':
        this._router.navigate([`${this.url}/secret/bulk-import`]);
        break;
      case 'register-manage':
        this._router.navigate([`${this.url}/secret/register-manage`]);
        break;
      case 'questions':
        this._router.navigate([`${this.url}/secret/questions`]);
        break;
        case 'popup-feedback-url':
          this._router.navigate([`${this.url}/secret/event-manage/popup-feedback-url`]);
          break;
    }
  }
}
