<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <mat-icon class="user-button__icon">person</mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="before">
  <!-- <div class="user-menu-title">
    <h4 class="user-menu-title__name">{{user.name}} {{user.lastName}}</h4>
    <a class="user-menu-title__link" [href]="flatlogicEmail">Flatlogic.com</a>
  </div>
  <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">person</mat-icon>Profile
  </button>
  <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">description</mat-icon>Tasks
  </button>
  <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">email</mat-icon>Messages
  </button> -->
  <div class="sign-button-wrapper">
    <button class="sign-button" mat-flat-button (click)="signOutEmit()">Sign out</button>
  </div>
</mat-menu>
