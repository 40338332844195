import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-ui-admin',
  templateUrl: './error-ui-admin.component.html',
  styleUrls: ['./error-ui-admin.component.scss']
})
export class ErrorUiAdminComponent implements OnInit {

  @Output() closeComp = new EventEmitter();
  @Input() showError: boolean;
  @Input() errorObj: any;
  errorMessage: string;

  constructor() { }

  ngOnInit(): void {
    this.mapErrors();
    setTimeout(() => {
      this.closeComp.emit(false);
    }, 3000000);
  }

  mapErrors() {
    // console.log("Error data : ", this.errorObj);
  }

  hideErrorComponent(value): void {
    this.closeComp.emit(value);
  }

}
