import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerService {

  private fetchData = new BehaviorSubject<any>('');
  newFetch = this.fetchData.asObservable();

  constructor() { }

  emitSpinnerState(flag, label='') {
    this.fetchData.next({flag: flag, label: label});
  }

}
