import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-ui-user',
  templateUrl: './error-ui-user.component.html',
  styleUrls: ['./error-ui-user.component.scss']
})
export class ErrorUiUserComponent implements OnInit {

  @Output() closeComp = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.closeComp.emit(false);
    }, 7000);
  }

  hideErrorComponent(value): void {
    this.closeComp.emit(value);
  }

}
