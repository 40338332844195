import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { ChatService } from 'src/app/shared/services/chat-service/chat.service';
import { Howl } from 'howler';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public isShowSidebar: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  adminFirstName: string;
  adminLastName: string;
  adminID: number;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _chatService: ChatService) {
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.isShowSidebar = !this.mobileQuery.matches;
    this._chatService.privateTechSupportMessageReceived()
      .subscribe(response => {
        if (+response["reciver_id"] === this.adminID) {
          // console.log("Layout : ", response)
          var sound = new Howl({
            src: ['../../../../assets/sound/chat_notify.mp3']
          });
          sound.play();
        }
      })
  }

  ngOnInit() {
    this.adminFirstName = sessionStorage.getItem("admin-first-name");
    this.adminLastName = sessionStorage.getItem("admin-last-name");
    this.adminID = +sessionStorage.getItem("admin-id");
    this._chatService.joinRoom({ user: this.adminFirstName + " " + this.adminLastName, room: "Lobby", userid: this.adminID });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);

    this.sidenav.close();
  }
}
