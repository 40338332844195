import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SignInComponent } from './sign-in/sign-in.component';
import { signInRoutes } from './sign-in.routing';
import { AuthGuardUser } from '../guards/auth.guard';
import { SharedUserModule } from '../../shared-user/shared-user.module';
import { ChatService } from '../../../shared/services/chat-service/chat.service';
import { MaterialModule } from 'src/app/admin-panel/shared/modules/material/material.module';
import { RegisterPopupComponent } from './sign-in/register-popup/register-popup.component';
import { UserSignInRootComponent } from './user-sign-in-root/user-sign-in-root.component';
@NgModule({
    declarations: [
        SignInComponent,
        RegisterPopupComponent,
        UserSignInRootComponent
    ],
    imports     : [
        RouterModule.forChild(signInRoutes),
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        SharedUserModule,
        MatProgressSpinnerModule
    ],
    providers: [
        AuthGuardUser,
        ChatService
    ]
})
export class AuthSignInModule
{
}