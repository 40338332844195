import { Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat-service/chat.service';

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss']
})
export class DummyComponent implements OnInit {

  dummyText: string;

  constructor(private _chatService: ChatService) { 
    // this._chatService.privateTechSupportMessageReceived()
    //   .subscribe(data => {
    //     console.log("Dummy Chat Data : ", data);
    //   })
  }

  ngOnInit(): void {
  }

  submitText() {
    // this._chatService.privateTechSupportMessage(
    //   { user: "User One", room: "Lobby", message: this.dummyText, sender_id: 60, reciver_id: 1 }
    // )
  }

}
