<!-- MAIN ADMIN ROLE -->
<mat-list *ngIf="role === 1">
  <mat-list-item (click)="navigate('admin-dashboard')" [ngClass]="route.includes('dashboard') ? 'active' : ''">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item (click)="navigate('main-panel')" [ngClass]="route.includes('main-admin') ? 'active' : ''">
    <mat-icon>explore</mat-icon> Manage Events
  </mat-list-item>
</mat-list>

<!-- EVENT MANAGER ROLE -->
<mat-list *ngIf="role === 2">
  <mat-list-item (click)="navigate('dashboard')" [ngClass]="route.includes('dashboard') ? 'active' : ''">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item (click)="navigate('notification')" [ngClass]="route.includes('notification') ? 'active' : ''">
    <mat-icon>notification_important</mat-icon> Notification
  </mat-list-item>
  <mat-list-item (click)="navigate('functionality')" [ngClass]="route.includes('functionality') ? 'active' : ''">
    <mat-icon>settings</mat-icon> Event Settings
  </mat-list-item>
  <mat-list-item (click)="navigate('add-url')" [ngClass]="route.includes('add-url') ? 'active' : ''">
    <mat-icon>http</mat-icon> Add URLs
  </mat-list-item>
  <mat-list-item (click)="navigate('polls')" [ngClass]="route.includes('polls') ? 'active' : ''">
    <mat-icon>assignment_turned_in</mat-icon> Polls
  </mat-list-item>
  <mat-list-item (click)="navigate('resources')" [ngClass]="route.includes('resources') ? 'active' : ''">
    <mat-icon>widgets</mat-icon> Resources
  </mat-list-item>
  <mat-list-item (click)="navigate('analytics')" [ngClass]="route.includes('analytics') ? 'active' : ''">
    <mat-icon>data_usage</mat-icon> Analytics
  </mat-list-item>
  <mat-list-item (click)="navigate('report')" [ngClass]="route.includes('report') ? 'active' : ''">
    <mat-icon>folder</mat-icon> Reports
  </mat-list-item>
  <mat-list-item (click)="navigate('popup-feedback-url')" [ngClass]="route.includes('popup-feedback-url') ? 'active' : ''">
    <mat-icon>folder</mat-icon> Popup Feedback
  </mat-list-item>
</mat-list>

<!-- REGISTRATION MANAGER ROLE -->
<mat-list *ngIf="role === 3">
  <mat-list-item (click)="navigate('dashboard')" [ngClass]="route.includes('dashboard') ? 'active' : ''">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item (click)="navigate('bulk-import')" [ngClass]="route.includes('bulk-import') ? 'active' : ''">
    <mat-icon>file_copy</mat-icon> Bulk Import Users
  </mat-list-item>
  <mat-list-item (click)="navigate('register-manage')" [ngClass]="route.includes('register-manage') ? 'active' : ''">
    <mat-icon>account_box</mat-icon> Registered Users
  </mat-list-item>
</mat-list>

<!-- TECH SUPPORT ROLE -->
<mat-list *ngIf="role === 4">
  <mat-list-item (click)="navigate('dashboard')" [ngClass]="route.includes('dashboard') ? 'active' : ''">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item (click)="navigate('tech_support')" [ngClass]="route.includes('tech-support') ? 'active' : ''">
    <mat-icon>record_voice_over</mat-icon> Tech Support
  </mat-list-item>
</mat-list>

<!-- QNA PANEL ROLE -->
<mat-list *ngIf="role === 5">
  <mat-list-item (click)="navigate('dashboard')" [ngClass]="route.includes('dashboard') ? 'active' : ''">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item (click)="navigate('questions')" [ngClass]="route.includes('questions') ? 'active' : ''">
    <mat-icon>question_answer</mat-icon> Questions
  </mat-list-item>
</mat-list>


<!-- <mat-list>
  <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <mat-list-item routerLink="{{routes.MAIN_ADMIN_MANAGE}}" routerLinkActive="active">
    <mat-icon>explore</mat-icon> Main Admin Panel
  </mat-list-item>
  <mat-list-item routerLink="{{routes.NOTIFICATION_EVENT}}" routerLinkActive="active">
    <mat-icon>notification_important</mat-icon> Notification
  </mat-list-item>
  <mat-list-item routerLink="{{routes.FUNCTIONALITY}}" routerLinkActive="active">
    <mat-icon>brush</mat-icon> Functionality
  </mat-list-item>
  <mat-list-item routerLink={{routes.ADD_URL}} routerLinkActive="active">
    <mat-icon>http</mat-icon> Add URLs
  </mat-list-item>
  <mat-list-item routerLink={{routes.BULK_IMPORT}} routerLinkActive="active">
    <mat-icon>file_copy</mat-icon> Bulk Import Users
  </mat-list-item>
  <mat-list-item routerLink={{routes.POLLS}} routerLinkActive="active">
    <mat-icon>assignment_turned_in</mat-icon> Polls
  </mat-list-item>
  <mat-list-item routerLink={{routes.QUESTIONS}} routerLinkActive="active">
    <mat-icon>question_answer</mat-icon> Questions
  </mat-list-item>
  <mat-list-item routerLink={{routes.RESOURCES}} routerLinkActive="active">
    <mat-icon>widgets</mat-icon> Resources
  </mat-list-item>
  <mat-list-item routerLink={{routes.ANALYTICS}} routerLinkActive="active">
    <mat-icon>data_usage</mat-icon> Analytics
  </mat-list-item>
  <mat-list-item routerLink={{routes.TECH_SUPPORT}} routerLinkActive="active">
    <mat-icon>record_voice_over</mat-icon> Tech Support
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.TYPOGRAPHY}}" routerLinkActive="active">
    <mat-icon>text_fields</mat-icon> Typography
  </mat-list-item>
  <mat-list-item routerLink="{{routes.TABLES}}" routerLinkActive="active">
    <mat-icon>border_all</mat-icon> Tables
  </mat-list-item>
  <mat-list-item routerLink="{{routes.NOTIFICATION}}" routerLinkActive="active">
    <mat-icon>notifications_none</mat-icon> Notification
  </mat-list-item>
  <mat-list-item (click)="openUiElements()">
    <mat-icon>filter_none</mat-icon> UI Elements
  </mat-list-item>
    <div>
      <mat-list-item class="ui-element" routerLink="{{routes.UI_ELEMENTS_ICONS}}" routerLinkActive="active">
        <div class="circle"></div>Icons
      </mat-list-item>
      <mat-list-item class="ui-element" routerLink="{{routes.UI_ELEMENTS_CHARTS}}" routerLinkActive="active">
        <div class="circle"></div>Charts
      </mat-list-item>
      <mat-list-item class="ui-element" routerLink="{{routes.UI_ELEMENTS_MAP}}" routerLinkActive="active">
        <div class="circle"></div>Map
      </mat-list-item>
    </div> -->
<!-- </mat-list> -->

<!-- <p class="sidebar-title">Help</p> -->

<!-- <mat-list>
  <a href="https://flatlogic.com/templates" target="_blank" style="text-decoration: none;">
    <mat-list-item>
      <mat-icon>library_books</mat-icon> Library
    </mat-list-item>
  </a>
  <a href="https://flatlogic.com/forum/" target="_blank" style="text-decoration: none;">
    <mat-list-item>
      <mat-icon>question_answer</mat-icon> Support
    </mat-list-item>
  </a>
  <a href="https://flatlogic.com/forum" target="_blank" style="text-decoration: none;">
    <mat-list-item>
      <mat-icon>help_outline</mat-icon> FAQ
    </mat-list-item>
  </a>
</mat-list>

<p class="sidebar-title">Projects</p>

<mat-list>
  <mat-list-item> <div class="project-circle project-circle_yellow"></div> My resend </mat-list-item>
  <mat-list-item> <div class="project-circle project-circle_blue"></div> Starred</mat-list-item>
  <mat-list-item> <div class="project-circle project-circle_pink"></div> Background </mat-list-item>
</mat-list> -->
