import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../shared/services/chat-service/chat.service';
import { AuthService } from '../user-panel/services/auth/auth.service';
import jwt_decode from "jwt-decode";
import { SharedService } from '../user-panel/auth/sign-in/sign-in/shared-service/shared.service';
import { ProgressSpinnerService } from '../shared-root/progress-spinner/progress-spiinner-service/progress-spinner.service';

@Component({
  selector: 'app-dynamic-root',
  templateUrl: './dynamic-root.component.html',
  styleUrls: ['./dynamic-root.component.scss']
})
export class DynamicRootComponent implements OnInit {
  location: string;
  imageUrl: string;
  isEventActive = true;
  checkImage: any;

  constructor(
    private _chatService: ChatService,
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _progressSpinnerService: ProgressSpinnerService
    // private _sharedService: SharedService
  ) {
    this._chatService.getImageIfEventOff()
      .subscribe(response => {
        let location = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : null;
        // console.log("RESPONSE : ", response)
        if (response["off_event_url"]["slug"] === location) {
          this.imageUrl = response["off_event_url"]["url"];
          // console.log("IMAGE URL : ", this.imageUrl);
          this.isEventActive = true;
          this.checkImageFn();
        }
      })
  }

  ngOnInit(): void {
    // this.location = (window.location.pathname.split("/")[1].trim() !== "" && window.location.pathname.split("/")[1] !== "secret") ? window.location.pathname.split("/")[1] : null;
    this.location = this._activatedRoute.snapshot.root.children[0]["params"]["dynamicPath"];
    // console.log("Dynamic Component Location : ", this.location);
    if (this.location) {
      // console.log(">>>>>>>>>> LOCATION IS PRESENT <<<<<<<<<<<");
      let decodeToken = this.getDecodedAccessToken(sessionStorage.getItem("access-admin-token"));
      if (decodeToken) {
        // console.log(">>>>>>>>>> DECODED EXISTS <<<<<<<<<<<");
        // CHECK SLUG IF EXISTS OR NOT
        this._progressSpinnerService.emitSpinnerState(true, 'Checking Event...');
        this._authService.getEventBySlug(this.location)
          .subscribe(response => {
            if (response["status_code"] === 200) {
              this._progressSpinnerService.emitSpinnerState(false);
              // console.log(">>>>>>>>>> SLUG PRESENT IN DB <<<<<<<<<<<");
              // SLUG IS PRESENT IN DB
              // console.log("Location : : : ", this.location);
              // console.log("Token Slug Location : : : ", decodeToken["user"]["data"]["slug"]);
              if (this.location !== decodeToken["user"]["data"]["slug"]) {
                // console.log(">>>>> LOCATION AND TOKEN SLUG NOT EQUAL");
                this.isEventActive = false;
                sessionStorage.clear();
                // console.log(">>>>> NAVIGATING TO SIGN IN PAGE");
                this._router.navigate([`${this.location}/secret/sign-in`]);
                this.checkSlug();
              } else {
                // console.log("LOCATION AND TOKEN SLUG ARE EQUAL");
                // this._sharedService.shareEventData(response["data"]);
                if (response["data"]["is_completed"]) {
                  this.imageUrl = response["data"]["off_image_url"];
                  this.checkImageFn();
                  this.isEventActive = true;
                } else {
                  this.isEventActive = false;
                }
              }
            } else {
              // SLUG IS NOT PRESENT
              this._progressSpinnerService.emitSpinnerState(false);
              // console.log(">>>>>>>>>> SLUG DOES NOT EXIST IN DB <<<<<<<<<<<");
              sessionStorage.clear();
              this.isEventActive = false;
              this._router.navigate([`${this.location}/event-not-found`]);
            }
          })
      } else {
        // console.log(">>>>>>>>>> LOCATION DOES NOT EXIST <<<<<<<<<<<");
        this.checkSlug();
      }
      // if (decodeToken && this.location !== decodeToken["user"]["data"]["slug"]) {
      //   sessionStorage.removeItem("access-admin-token");
      //   this._router.navigate([`${this.location}/event-not-found`]);
      // }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  checkSlug() {
    this._progressSpinnerService.emitSpinnerState(true, 'Checking Event...');
    this._authService.getEventBySlug(this.location)
    .subscribe(response => {
      // console.log("Check SLUG RESPONSE : ", response)
      if (response["status_code"] === 200) {
          this._progressSpinnerService.emitSpinnerState(false);
          // this._sharedService.shareEventData(response["data"]);
          if (response["data"]["is_completed"]) {
            this.imageUrl = response["data"]["off_image_url"];
            this.checkImageFn();
            this.isEventActive = true;
          } else {
            this.isEventActive = false;
          }
        } else {
          this._progressSpinnerService.emitSpinnerState(false);
          this.isEventActive = false;
          this._router.navigate([`${this.location}/event-not-found`]);
        }
      }, (error) => {
        this._progressSpinnerService.emitSpinnerState(false);
        this.isEventActive = false;
        this._router.navigate([`${this.location}/event-not-found`]);
      })
  }

  checkImageFn() {
    // console.log("IN CHECK FUNCTION")
    this.checkImage = {
      'background-image': `url(${this.imageUrl})`,
      'height': '100%',
      'width': '100%',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%'
    }
    // console.log("IMAGE OBJECT : ", this.checkImage)
  }

}
