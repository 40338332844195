import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl1 = environment.apiUrl;

  constructor(private _httpClient: HttpClient) { }

  signIn(isAdmin: string, isOtp: string, email: string, password: string, slug: string): Observable<any> {
    const body = new HttpParams()
    .set('isAdmin', isAdmin)
    .set('isOtp', isOtp)
    .set('email', email)
    .set('password', password)
    .set('slug', slug);
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')

    // { Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1c19jb2RlIjoyMDAsInN0YXR1cyI6InN1Y2Nlc3MiLCJkYXRhIjp7ImlkIjoxLCJyb2xlX2lkIjoxLCJmaXJzdF9uYW1lIjoiYWRtaW4iLCJsYXN0X25hbWUiOiJhZG1pbiIsInVzZXJuYW1lIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJEVxVUVISjEyMWpwZVBQU1FZcFNqTk82VFA5V2ZCWEhnblh4VFRnU1VmTnIvbFE5Sm1INk9tIiwicHJvZmlsZV9pbWciOm51bGwsImNyZWF0ZWRBdCI6IjIwMjAtMDYtMThUMTM6NTQ6MDcuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjAtMDYtMThUMTM6NTQ6MDcuMDAwWiJ9fSwiaWF0IjoxNjI1ODE1MTU0LCJleHAiOjE2MjU4NTExNTR9.BeS5LX8fuZUH__VV8NMsY47wqI-mmPQD2GEDjgNz86k' },
    // { 'Content-Type', }
    return this._httpClient.post(`${this.apiUrl1}api/auth/login`, body.toString(), {
      headers: httpHeaders
    })
    .pipe(catchError(this.errorHandler));
  }

  signInTempQna(emailID, password, isQNA) {
    return this._httpClient.post(`${this.apiUrl1}api/auth/qnaLogin`, {email: emailID, password: password, is_qna: isQNA})
    .pipe(catchError(this.errorHandler));
  }

  getEventBySlug(slug) {
    return this._httpClient.get(`${this.apiUrl1}api/admin/getEventBySlug/${slug}`)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
