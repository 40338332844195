import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicRootSharedService {

  private currentData = new BehaviorSubject<any>('');
  currentDataFetch = this.currentData.asObservable();

  private widgetData = new BehaviorSubject<any>('');
  emitWidgetData = this.widgetData.asObservable();

  constructor() { }

  emitData(newData){
    this.currentData.next(newData);
  }

  emitWidgetDetails(newData) {
    this.widgetData.next(newData);
  }
}
