import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AuthPageComponent } from './containers';
import { AuthRoutingModule } from './auth-routing.module';
import { YearPipe } from './pipes';
import { AuthService, EmailService } from './services';
import { LoginFormComponent, SignFormComponent } from './components';
import { AuthGuard } from './guards';
import { SharedModule } from '../../shared/shared.module';
import { MaterialModule } from '../../shared/modules/material/material.module';
import { AuthTempQnAGuard } from './guards/auth-temp-qna-guard';
import { AuthPageRootComponent } from './containers/auth-page-root/auth-page-root.component';

@NgModule({
  declarations: [
    AuthPageComponent,
    YearPipe,
    LoginFormComponent,
    SignFormComponent,
    AuthPageRootComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CommonModule
  ],
  providers: [
    AuthService,
    EmailService,
    AuthGuard,
    AuthTempQnAGuard
  ]
})
export class AuthModule { }
