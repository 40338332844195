<div style="overflow-y: hidden;" [ngStyle]="backgroundImageLogin">
    <div class="col-md-12">
        <div *ngIf="topLogosData?.logo_type == 'band'">
            <div class="position-absolute" *ngFor="let topLogo of topLogosData?.logo_data" style="width:99.9%"
                [style.height.px]="topLogo?.height" [style.top.px]="topLogo?.top" [style.left.px]="topLogo?.left">
                <img *ngIf="topLogosData?.logo_type == 'band'" [src]="topLogo?.image_url" alt="Background Image"
                    class="image-full">
            </div>
        </div>

        <div *ngIf="topLogosData?.logo_type == 'multiple'">
            <div *ngFor="let topLogo of topLogosData?.logo_data" style="width:99.9%">
                <img class="position-absolute" *ngIf="topLogosData?.logo_type == 'multiple'" [src]="topLogo?.image_url"
                    alt="Background Image" [ngStyle]="topLogo.topClass">
            </div>
        </div>

        <div *ngIf="bottomLogosData?.logo_type == 'band'">
            <div class="position-absolute" *ngFor="let bottomLogo of bottomLogosData?.logo_data" style="width:99.9%"
                [style.height.px]="bottomLogo?.height" [style.bottom.px]="bottomLogo?.top"
                [style.left.px]="bottomLogo?.left">
                <img *ngIf="bottomLogosData?.logo_type == 'band'" [src]="bottomLogo?.image_url" alt="Background Image"
                    class="image-full">
            </div>
        </div>

        <div *ngIf="bottomLogosData?.logo_type == 'multiple'">
            <div *ngFor="let bottomLogo of bottomLogosData?.logo_data">
                <img class="position-absolute" [style.width.px]="bottomLogo?.width"
                    [style.height.px]="bottomLogo?.height" [style.bottom.px]="bottomLogo?.top"
                    [style.left.px]="bottomLogo?.left" *ngIf="bottomLogosData?.logo_type == 'multiple'"
                    [src]="bottomLogo?.image_url" alt="Background Image">
            </div>
            <!-- </div> -->
        </div>

        <div id="wrapper-sign-in" class="wrapper-sign-in screen-scroll">
            <div class="container-fluid contenttext-sign-in">
                <!-- <h1>Librarian Forum India 2021</h1>
                            <h2>Role of the librarians in promoting research<br>integrity and overall institutional profile</h2>
                            <h4>September 02 2021, 10:30 AM IST</h4> -->
            </div>
            <div class="container text-center" [ngStyle]="formPosition">
                <div class="row">
                    <div class="col-12">

                        <form class="loinbox-sign-in" [formGroup]="signInForm">
                            <div class="">
                                <div class="form-group col-12 p-0">
                                    <label for="name" class="sr-only">Email Id</label>
                                    <input
                                        *ngIf="(eventData?.event_registration_type === 'open' || eventData?.event_registration_type === 'closed') && (eventData?.closed_registration_type === 'email' || eventData?.closed_registration_type === '')"
                                        type="email" [formControlName]="'email'" class="form-control" id="name"
                                        placeholder="Email Id">
                                    <input
                                        *ngIf="eventData?.event_registration_type === 'closed' && (eventData?.closed_registration_type === 'emailOtp' || eventData?.closed_registration_type === 'emailMobileOtp') && !moveToOtp"
                                        type="text" class="form-control" placeholder="Email Id"
                                        [formControlName]="'emailClosed'">
                                    <input
                                        *ngIf="eventData?.event_registration_type === 'closed' && (eventData?.closed_registration_type === 'emailOtp' || eventData?.closed_registration_type === 'emailMobileOtp') && moveToOtp"
                                        type="number" [formControlName]="'otp'" class="form-control" id="otp"
                                        placeholder="PASSCODE">
                                    <small class="form-text" [ngStyle]="notRegisteredText">Not Registered?
                                        <a href="#" class="signup" data-toggle="modal" data-target="#loginpop"
                                            (click)="openRegisterPopup()" [ngStyle]="clickHereText">Click here</a>
                                    </small>
                                </div>
                                <div class="form-group col-12 p-0 text-center mb-0">
                                    <!-- <a href="#" (click)="login($event)" class="btn login"><img src="../../../../../assets/images/login.png" class="joinbtnimg"></a> -->
                                    <button class="login-button-custom"
                                        *ngIf="(eventData?.event_registration_type === 'open' || eventData?.event_registration_type === 'closed') && (eventData?.closed_registration_type === 'email' || eventData?.closed_registration_type === '')"
                                        (click)="login($event)" [ngStyle]="buttonColor">
                                        Login
                                    </button>
                                    <button class="login-button-custom" (click)="loginClosed($event)"
                                        [ngStyle]="buttonColor"
                                        *ngIf="eventData?.event_registration_type === 'closed' && (eventData?.closed_registration_type === 'emailOtp' || eventData?.closed_registration_type === 'emailMobileOtp') && !moveToOtp">
                                        Continue
                                    </button>
                                    <button class="login-button-custom" (click)="verifyAndLogin($event)"
                                        [ngStyle]="buttonColor"
                                        *ngIf="eventData?.event_registration_type === 'closed' && (eventData?.closed_registration_type === 'emailOtp' || eventData?.closed_registration_type === 'emailMobileOtp') && moveToOtp">Verify
                                        & Login</button>
                                </div>
                                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div id="warning-message">
                <p>This website is only viewable in landscape mode. Rotate Your Mobile</p>
            </div>
        </div>

        <app-register-popup *ngIf="openRegister" (closeEvt)="closeRegister($event)"></app-register-popup>