<div class="backdrop" (click)="closeEvent('modal')"></div>
<div class="modal">
  <div style="width: 100%; display: flex; justify-content: flex-end;">
    <div style="padding: 5px 5px; font-size: 25px; line-height: 10px; cursor: pointer;" [ngStyle]="closeRegisterPopupColor" (click)="closeEvent('button')">
      <img src="assets/images/close.png" width="20px">
    </div>
  </div>
  <div class="modal-div" [ngStyle]="modalDiv">
    <div class="modal-sub-div">
      <h2 class="modal-header" [ngStyle]="headingColor">Register</h2>
      <form [formGroup]="registerForm">
        <div class="modal-form-div">
          <input name="firstName" type="text" [formControlName]="'firstName'" placeholder="First Name" autocomplete="off" class="form-control" style="margin-bottom: 15px;">
          <input name="lastName" type="text" [formControlName]="'lastName'" placeholder="Last Name" autocomplete="off" class="form-control" style="margin-bottom: 15px;">
          <input name="emailID" type="text" [formControlName]="'email'" placeholder="Email ID" autocomplete="off" class="form-control" style="margin-bottom: 15px;">
          <div *ngFor="let field of eventData?.dynamic_fields">
            <input type="text" placeholder={{field.name}} class="form-control" style="margin-bottom: 15px;" [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}" (keypress)="preserveNumber($event, field.name)">
          </div>
        </div>
        <div class="modal-form-btn-div">
          <button class="register-button-custom" [ngStyle]="registerButtonColor" (click)="register()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="registeringUser"></span>
            Register
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="backdrop" (click)="closeEvent()"></div>
<div class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="close" (click)="closeEvent()" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-header text-center">
        <h5 class="modal-title" id="exampleModalLongTitle">Register</h5>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="First Name">
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Last Name">
          </div>
          <div class="form-group">
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Email Id">
          </div>
          <div class="form-group text-center">
            <button type="submit" class="btn p-0"><img src="../../../../../../assets/images/submit.png" class="submit"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->