<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <div>
    <label style="color: rgb(0, 0, 0);">Email Address</label>
    <input class="form-control w-100" placeholder="Email Address" autocomplete="false" type="email" formControlName="email">
  </div><br>

  <div>
    <label style="color: rgb(0, 0, 0);">Password</label>
    <input class="form-control w-100" placeholder="Password" autocomplete="false" type="password" formControlName="password">
  </div><br>
  <!-- <mat-form-field class="form__input" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput placeholder="Email Address" type="email" formControlName="email">
  </mat-form-field> -->

  <!-- <mat-form-field class="form__input" appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput placeholder="Password" type="password" formControlName="password">
  </mat-form-field> -->

  <div class="form-actions">
    <button class="form-actions__login" mat-raised-button color="primary" type="submit">
      <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span> -->
      Login
    </button>
    <!-- <button class="form-actions__forget" mat-button>Forget password</button> -->
  </div>
</form>
